<template lang="pug">
.container
  page-title
  .card
    .card-content
      app-form(@submit.prevent='getVisits' :is-saving='isLoading' button-text='Consultar')
        field(label='Fecha inicial' fied='startDate')
          app-date(id='startDate' v-model='params.startDate' type='datetime-local' required)
        field(label='Fecha final' fied='endDate')
          app-date(id='endDate' v-model='params.endDate' type='datetime-local' required)
      div(v-if='visits.length')
        //- b-button(@click='back') Atrás
        export-button.ml-2(:data='visits' :columns='exportColumns' name='visitas')
      hr
      visits-table(:items='visits' :is-loading='isLoading')
</template>

<script>
import VisitsTable from './blocks/VisitsTable'
import { inpointNewSchedule } from '@/router/routes'
import visitsService from '@/services/visits.service'
import reportsService from '@/services/reports.service'

const exportColumns = [
  { headerName: 'Fecha y hora', field: 'date' },
  { headerName: 'Ubicación', field: 'locationName' },
  { headerName: 'Usuario', field: 'userName' }
]

export default {
  components: { VisitsTable },
  data () {
    return {
      params: {
        ...reportsService.getStartEndDateTimes()
      },
      visits: [],
      isLoading: false,
      exportColumns,
      inpointNewSchedule
    }
  },
  created () {
    this.getVisits()
  },
  methods: {
    async getVisits () {
      this.isLoading = true
      const result = await visitsService.getAll(this.params)
      if (result) {
        this.visits = result
      }
      this.isLoading = false
    }
  }
}
</script>

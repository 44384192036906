<template lang="pug">
div
  i(v-if='isLoading') Descargando imagen...
  img(:src='bucketSrc' v-else)
</template>

<script>
import filesService from '@/services/files.service'
export default {
  props: {
    src: { type: String }
  },
  data () {
    return {
      bucketSrc: null,
      isLoading: false
    }
  },
  async created () {
    this.isLoading = true
    const result = await filesService.getPreviewUrl(this.src)
    if (result) {
      this.bucketSrc = result
    }
    this.isLoading = false
  }
}
</script>

<template lang="pug">
div(ref='event')
  div Fecha de la visita: {{ visit.date | datetime }}
  div Sitio: {{ visit.locationName }}
  div Usuario que hizo la visita: {{ visit.userName }}
  a(@click='print') Imprimir esta página
  hr
  div Severidad: {{ severityDescriptions[event.severity] }}
    span.ml-2.security-event-indicator(:style='{ "background-color": severityColors[event.severity] }')
  div Comentarios: {{ event.comments }}
  div.mt-5(v-for='photo in photos')
    image-viewer(:src='photo')
</template>

<script>
import ImageViewer from '@/components/shared/ImageViewer'
import { securityEventSeverityDescriptions, securityEventSeverityColors } from '@/constants/enums'

export default {
  components: { ImageViewer },
  props: {
    event: { type: Object, require: true },
    visit: { type: Object, require: true }
  },
  data () {
    return {
      severityDescriptions: securityEventSeverityDescriptions,
      severityColors: securityEventSeverityColors
    }
  },
  computed: {
    photos () {
      if (!this.event.photos) return []
      return this.event.photos.split(',')
    }
  },
  methods: {
    print () {
      var mywindow = window.open('', 'PRINT', 'height=400,width=600')
      mywindow.document.write('<html><head><title>' + document.title + '</title>')
      mywindow.document.write('</head><body >')
      mywindow.document.write(this.$refs.event.innerHTML)
      mywindow.document.write('</body></html>')
      mywindow.document.close() // necessary for IE >= 10
      mywindow.focus() // necessary for IE >= 10*/
      mywindow.print()
    }
  }
}
</script>

<style lang="scss" scoped>
.security-event-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>

<template lang="pug">
b-table(:data='items' :loading='isLoading' narrowed hoverable mobile-cards striped searchable)
  b-table-column(field='date' label='Fecha y hora' sortable searchable v-slot='props')
    | {{ props.row.date | datetime }}
  b-table-column(field='locationName' label='Ubicación' sortable searchable v-slot='props') {{ props.row.locationName }}
  b-table-column(field='userName' label='Usuario' sortable searchable v-slot='props') {{ props.row.userName }}
  b-table-column(label='Eventos' v-slot='props')
    a(v-for='event in props.row.securityEvents' @click='showVisit(props.row, event)')
      span.security-event-indicator.mr-1(:style='{ "background-color": securityEventSeverityColors[event.severity] }')
  template(slot='empty')
    .no-data-found
  b-modal(v-model='eventModalActive' has-modal-card trap-focus aria-role='dialog' aria-modal)
    modal-card(v-if='eventSelected' title='Evento')
      show-visit-event(:event='eventSelected' :visit='visitSelected')
</template>

<script>
import { unitOfMeasureDescriptions, securityEventSeverityColors } from '@/constants/enums'
import toastService from '@/services/toast.service'
import schedulesService from '@/services/schedules.service'
import ShowVisitEvent from './ShowVisitEvent.vue'

export default {
  components: { ShowVisitEvent },
  props: {
    items: { type: Array, required: true },
    isLoading: { type: Boolean }
  },
  data () {
    return {
      securityEventSeverityColors,
      eventModalActive: false,
      visitSelected: null,
      eventSelected: null
    }
  },
  watch: {
    items: {
      inmediate: true,
      handler: function () {
        this.items.forEach(invoice => {
          this.formatItem(invoice)
        })
      }
    }
  },
  methods: {
    formatItem (item) {
      item.unitOfMeasureDescription = unitOfMeasureDescriptions[item.unitOfMeasure]
    },
    showVisit (visit, event) {
      this.visitSelected = { ...visit }
      this.eventSelected = { ...event }
      this.eventModalActive = true
    },
    removeSchedule (schedule) {
      toastService.confirm('¿Desea eliminar esta programación?, no podrá recuperarla después', async () => {
        const result = await schedulesService.delete(schedule)
        if (result) {
          toastService.show('Programación eliminada')
          this.$emit('removed', schedule)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.security-event-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
